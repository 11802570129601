#vnw {
    position: absolute;
    bottom: 1rem;
    left: 2rem;
    z-index: 999;
}

#vnw span {
    color: grey;
    font-size: 1rem;
}