#countdownWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  color: white;
  font-size: 2.5vw;
  line-height: 2.6vw;
  width: 100%;
  margin-top: 2rem;
}

#countdownWrapper span {
  margin-top: 0vw;
}

.counter {
  text-align: center;
  margin: 0;
  width: 4vw;
  width: 100%;
}

.counterLabel {
  margin: 0;
  margin-top: 1rem;
  text-align: center;
  font-size: 2rem;
}

.days,
.hours,
.mins,
.secs {
  margin: 0 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 5.5vw;
}

@media screen and (min-width: 1268px) {
  #countdownWrapper {
    font-size: 3rem;
    line-height: 3.1rem;
  }
}

@media screen and (max-width: 768px) {
  #countdownWrapper {
    font-size: 4vw;
    line-height: 150%;
  }

  .counterLabel {
    margin: 0;
    text-align: center;
    font-size: 3vw;
  }

  #countdownWrapper span {
    margin-top: 1vw;
  }

  .days,
  .hours,
  .mins,
  .secs {
    width: 8vw;
  }
}

@media screen and (max-width: 565px) {
  #countdownWrapper {
    font-size: 3rem;
    line-height: 3.1rem;
  }

  .counter {
    width: 8rem;
    line-height: 5rem;
  }

  .days,
  .hours,
  .mins,
  .secs {
    width: 20%;
  }
}
