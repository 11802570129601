.top-links {
    list-style: none;
    margin:0;
    padding: 0;
    height: 100%;
    display: flex;
    justify-items: center;
    align-items: center;
    text-align: center;
    font-size: 1.6rem;
    color: var(--secondary);
  }

  .top-links li {
    margin: 1rem;
    font-size: 2.2rem;
  }

  .top-links a, .top-links__logout {
    text-decoration: none;
    color: var(--secondary);
    transition: .3s;
  }

  .top-links a {
    padding: 0.5rem;
  }


.top-links a:hover,
.top-links a.active, 
.top-links__logout:hover
 {
  
  color: white;
  
}

.top-links__logout:active{
  color: white;
}

.top-links__logout {
    cursor: pointer;
    background: transparent;
    font: inherit;
    border: none;
  }
  
  .top-links__logout:focus {
    outline: none;
    color: white;
  }
  
  .top-links__logout:hover,
  .top-links__logout:active {
    color: white;
  }
  

  