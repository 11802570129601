#langSelSm {
  margin: 0;
  display: flex;
  align-items: center;
}

.regionSel {
  margin: 1rem;
  padding: 1rem;
  background-color: var(--secondary);
  color: white;
  border-radius: .5rem;
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  cursor: pointer;
  transition: .3s;
}

.regionSel:hover  {
  color: var(--primary);
}

.langName {
  margin: 0 1rem 0 .5rem;
  text-transform: uppercase;
  font-weight: 600;
}

.langDrawWrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  background-image: linear-gradient(to left bottom, #202020, #242424, #282828, #2d2d2d, #313131);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  box-shadow: 1rem 0 2rem rgba(0, 0, 0, .4);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  z-index: 2;
}

.closeBtn {
  position: absolute;
  top: 2rem;
  right: 2rem;
  background-color: transparent;
  border: none;
  font-size: 2rem;
  font-weight: 300;
  color: var(--primary);
  z-index: 3;
}