#LangSelReg {
    position: relative;
    margin: 0;
    margin-bottom: 2rem;
    padding: 2rem;
    padding-top: 3rem;
    background-color: #262626;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    border-radius: 1rem;
    
}

.itemWrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 15rem;
    opacity: .6;
    transition: .3s;
    cursor: pointer;
}

.itemWrapper:hover {
    opacity: 1;
}

.iconWrapper {
    color: var(--primary);
    padding: 1rem;
    font-size: 1.5rem;
}

.titleWrapper {
    
}

.title {
    font-family: 'JCB-Roman',Arial,Helvetica,sans-serif;
    font-weight: 300;
    font-size: 1.8rem;
}

.instruction {
    position: absolute;
    top:0;
    left: 2rem;
    width: 100%;
    font-size: 1.2rem;
    color: var(--primary);
    
}

.inputError {
    background-color: red !important;
}

@media only screen and (max-width:768px) {
    #LangSelReg {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}
