#wrapper {
    margin: 0;
    padding: 0;
    position: relative;
    min-height:95vh;
    display: flex;
    align-items: stretch;
}

#leftContiner, 
#rightContainer {
    margin: 0;
    padding: 0;
    position: relative;
    display: flex;
}

#leftContiner {
    
    width: 50%;
    justify-content: flex-end;
    align-items: center;
}

#rightContainer {
    width: 50%;
    /* background: url("../../images/dig01.png") no-repeat center center; */
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -ms-background-size: cover;
    -o-background-size: cover;
     background-size: cover;
     justify-content: flex-start;
    align-items: center;
}

.branding {
    margin: 0 auto;
    width: 50%;
    max-width: 60rem;
    min-width: 12rem;
}

.authLangWrapper {
    position: fixed;
    top: 2rem;
    right: 2rem;
    z-index: 2000;
}

.langSel {
    background-color: var(--primary) !important;
    color: var(--secondary) !important;
}

@media screen and (max-width: 624px) {

    #wrapper {
        flex-direction: column;
    }
    
    #leftContiner, 
    #rightContainer {
        justify-content: center;
        width: 100%;
    }

    #leftContiner {
        min-height: 30vh;
    }

    #rightContainer {
        min-height: 65vh;
        align-items: flex-start;
        margin-bottom: 6rem;
    }

    .branding {
        width: 30%;
        margin-top: 4rem;
    }

    .authLangWrapper {
        position: absolute;
        top: .2rem;
        right: .2rem;
    }

    .langSel {
        background-color: black !important;
        color: var(--primary) !important;
    }

}