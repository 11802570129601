@import url('https://fonts.googleapis.com/css2?family=Montagu+Slab:wght@100;200;300;400;500;600;700&family=Urbanist:wght@100;200;300;400;500;600;800;900&display=swap');


:root {
  --primary-100: #e2bfa7;
  --primary: #af8c74;
  --primary-500: #7c5941;

  --secondary-100: #333;
  --secondary: #262626;
  --secondary-500: #000;

  --input-color: #797979;
  --input-border: #8c8c8c;
  --input-background: #fff;
  --input-placeholder: #CBD1DC;

  --input-border-focus: var(--primary-500);

  --group-color: #333;
  --group-border: var(--input-border);
  --group-background: #ededed;

  --group-color-focus: #fff;
  --group-border-focus: var(--input-border-focus);
  --group-background-focus: var(--primary);

}

html {
  margin: 0;
  position: relative;
  min-height: 100vh;
  font-size: 62.5%;
}

body {
  margin: 0; 
  min-height: 650px;
  font-family: 'Urbanist', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  color: #c3c3c3;
  background-image: url('https://myevent.eventstreams.co/img/bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

h1, h2, h3, h4 {
  font-family: 'Montagu Slab', serif;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

main {
  flex-grow: 2;
}


a {
  color:white;
  font-size: 1.4rem;
  text-decoration: none;
}

a:hover {
  color: var(--primary-500);
}

.helogale {
  color: white;
  text-decoration: none;
  opacity: .6;
}

.center {
  margin: 0 auto;
  text-align:  center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.right {
  display: flex;
  justify-content: flex-end;
  text-align: right;
}

.left {
  display: flex;
  justify-content: flex-start;
  text-align: left;
}

.wrapper {
  margin: 0 auto;
  width: 100%;
  max-width: 1750px;
}

.page-wrapper {
  margin: 0 auto;
  padding:0;
  margin-bottom: 5.6rem;
  width: 100%;
  min-height: 500px;
}

.header-container {
  position: fixed;
  z-index: 500 !important;
}

.vjs-play-contol {
  visibility: hidden !important;
}