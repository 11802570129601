#complete {
    margin: 0;
    display: flex;
}

.leftContainer {
    width: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.rightContainer {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mainTitle {
    margin: 0;
    color: var(--primary);
    font-size: 6rem;
    line-height: 6.2rem;
}

.subTitle {
    text-align: center;
    color: white;
    font-size: 3rem;
}