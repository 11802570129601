#agendaWrapper{
    margin: 0;
    padding: 0;
    position: fixed;
    width: 90px;
    height: 90px;
    bottom: 240px;
    right: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--primary);
    border-radius: 50%;
    box-shadow: 0 0 .4rem rgba(0, 0, 0, .4);
    z-index: 1002;
    color: var(--secondary);
    font-size: 3rem;
    transition: .3s;
    transform:  rotate(0deg);
    z-index: 900;
}

.agendaBody {
    display: flex;
    flex-direction: column;

}

.listTitle {
    color: var(--primary);;
}



#agendaWrapper span {
    font-size: 1.2rem;
}

#agendaWrapper:hover {
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, .4);
    transform:   scale(1.1);
}

#agendaWrapper:active {
    box-shadow: 0 0rem .2rem rgba(0, 0, 0, .6);
    transform:  scale(.8);
}

.agendaDrawWrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    width: 50rem;
    background-color: rgba(0, 0, 0, .8);
    z-index: 1000;
    display: flex;
    flex-direction: column;
    box-shadow: 1rem 0 2rem rgba(0, 0, 0, .4);
    overflow: hidden;
    
}

.editorWrapper {
    background-color: whitesmoke;
    /* height: 15rem; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}


.titleWrapper {
    background-color: var(--secondary);
    border-bottom: 2px solid var(--primary);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.title {
    font-size: 2.5rem;
    font-weight: 300;
    color: white;
}

.agendaBodyWrapper {
    position: relative;
    display: flex;
    flex-grow: 2;
    flex-direction: column;
    justify-content: flex-start;
    overflow-y: scroll;
}

.askQuestionForm {
    margin: 0;
}

.closeBtn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    padding: 1rem 0;
    cursor: pointer;
    transition: .3s;
}

.closeBtn {
    border-right: 1px solid white;
}

.closeBtn:hover {
    background-color: var(--secondary);
}

.closeIcon {
    font-size: 3rem;
}

.closeText {
    margin: 0;
    font-size: 1.4rem;
}

.centerSpinner {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.closeWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-content: center;
    background-color: var(--primary);
    color: white;
}

.loadingSpinner {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.loadingText {
    color: white;
    font-size: 1.4rem;
    font-weight: 300;
}

@media only screen and (max-width:500px) {
    #agendaWrapper {   
        width: 60px;
        height: 60px;
        bottom: 160px;
        right: 20px;
        font-size: 2rem;
    }

    .agendaDrawWrapper {
        width: 100%;
    }

    .closeIcon {
        font-size: 2rem;
    }
    
    .closeText {
        font-size: 1.1rem;
    }
}