.branding {
    width: 100% ;
    height: 100%;
    display: flex; 
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 0;
   
  }

  .branding_inner_wrapper {
    width: 100%;
    max-width: 1756px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  
  .branding .branding_inner_wrapper img {
    display: block; 
    width: 100%;
    height: 100%;
    object-fit: contain;
    padding: 1rem;
    
  }

  .branding_inner_title {
    margin-left: .5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .branding_inner_title h1 {
    margin: 0; 
    margin-left: -1.5rem; 
    padding: .6rem 0rem;
    font-size: 2.5rem;
    font-weight: 400;
    color: var(--secondary);
    border-radius: .3rem;
    line-height: 100%;
    width: fit-content;
  }

  .branding_header_logo {
    width: 140px !important;
  }

  .branding_inner_title h2 {
    margin: 0; 
    margin-top: -1rem;
    padding: .6rem 0rem;
    font-size: 1.6rem;
    font-weight: 400;
    width: fit-content;
    border-radius: .3rem;
    line-height: 100%;
    margin-left: -1.5rem; 
color: white;
  }

  @media only screen and (max-width: 574px) {
    .branding_inner_title h1 {
      font-size: 5vw;
      
      
    }

    .branding_header_logo {
      width: 16vw !important;
    }
  }