.vjs-modal-dialog-content, .vjs-error-display {
    display: none;
}

.vjs-control-bar {
    display: none;
    width: auto !important;
    position: absolute;
    border-radius: 0.5rem;
    bottom: 3em !important;
    left: 3em !important;
    right: 3em !important;
    height: 3em;
    background-color: #2b333f;
    background-color: rgba(0, 0, 0,.7) !important;
}

.vjs-loading-spinner {
    display: none !important;
}

@media screen and (max-width:756px) {
    .vjs-control-bar {
        bottom: 0em !important;
        left: 0em !important;
        right: 0em !important;
        border-radius: 0rem;
    }
}