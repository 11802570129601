.wrapper {
    width: 100%;
    padding-top: 56.25%;
    height: 0px;
    position: relative;
    background-color: black;
    box-shadow: 0rem 0rem 2rem rgba(0, 0, 0, .9);
}

.content {
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 6rem;
}

.title {
    font-size: 2rem;
}

.text {
    font-size: 1.4rem;
    margin: 0;
}