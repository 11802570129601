.pollChartContainer {
    position: relative;
    height: 300px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.poll-no-data-notice {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.poll-no-data-title {
    color: white;
    margin: 0;
    font-size: 3rem;
    font-weight: 300;
}
.poll-no-data-text {
    margin: 0;
    margin-top: 1rem;
    color: white;
    font-size: 1.4rem;
    max-width: 300px;
}

.tooltipWrapper {
    background: var(--secondary);
    padding: 1rem;
    border-radius: .5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 .3rem .6rem rgba(0, 0, 0, .5);
    border: .1rem solid grey;
}
.title {
    margin: 0;
    font-size: 1.4rem;
    font-weight: 500;
}

.value {
    margin: 0;
    font-size: 2.5rem;
    font-weight: 900;
}