
.aContainer {
    display: flex;
    justify-content: flex-Start;
    align-items: flex-end;
    flex-direction: column;
}

.aBody {
    display: flex;
    width: fit-content;
    max-width: 70%;
    margin: 1rem 0rem 1rem 1rem  ;
    padding: 1rem 2rem;
    padding-bottom: 2.2rem;
    width: fit-content;
    background-color:var(--primary);
    border-radius: 1rem 0 0 1rem;
    color: var(--secondary);
    font-size: 1.4rem;
}

.aInfo {
    display: flex;
    margin-top: -1rem;
}

.aAuthor {
    display: flex;
    margin: 0;
    margin-right: 1rem;
}

.aTimestamp {
    display: flex;
    margin: 0;
    margin-right: 1rem;
}

.answerInfoText {
    margin: 0;
    margin-top: .5rem;
}