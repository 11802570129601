#liveStream {
    max-height: 60rem;
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 1rem .1rem;
    background-image: url('../../../../public/assets/images/coff02.jpg');
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
}

.streamWrapper {
    width: 100%;
}

#video {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 30rem;
   
}

.videoContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 106.6rem;
    max-height: 60rem;
    
}

#videoPlayerWrapper {
    width: 100%;
    height: 100%;
}

.player {
    width: 100vw;
    max-width: 106.6rem;
    height: 50vw;
    max-height: 90vh;
}

.playbtn, .fullscreenBtn {
    position: absolute;
    bottom: 1.5rem;
    left: 1%;
    background-color: var(--primary);
    border: 0px solid var(--primary-500);
    height: 6rem;
    width: 6rem;
    border-radius: 50%;
    color: var(--secondary);
    font-size: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: .5;
    transition: .3s;
    box-shadow: 0 .3 .3 rgba(0, 0, 0, .4);
}

.fullscreenBtn {
    height: 4rem;
    width: 4rem;
    left: calc(1% + 6rem);
}

.playbtn:hover, .fullscreenBtn:hover {
    opacity: 1;
}


@media (min-width:1920px) {
    .marquee {
        font-size: 1.8rem;
    }

}
@media (min-width:1340px) {
    .marquee {
        font-size: 1.6rem;
    }
}

@media (max-width:1340px) {
    #liveStream {
       flex-direction: column;
    }
    #video, #info {
        
        width:95%;
    } 
}

