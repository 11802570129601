#langItem {
    margin: 1rem;
    padding: 1rem;
    border-radius: .5rem;
    
    color: grey;
    font-size: 2rem;
   transition: .3s;
   display: flex;
   /* box-shadow: 0 0 .6rem rgba(0, 0, 0, .4); */
}

#langItem:hover { 
    border-radius: .5rem;
    background-color: var(--primary);
    color: black;
    transform: scale(1.1);
}