.button {
    margin: 1.5rem;
    padding: 1rem 2rem;
    display: flex;
    justify-content: center;
    border-radius: 5px;
    border-width: 1px;
    background-color: var(--primary);
    border: 1px solid var(--primary-500);
    color: white;
    font-weight: 600;
    cursor: pointer;
    position: relative;
    transition: all .35s;
    font-family: 'JCB-Bold-Con', sans-serif;
    text-transform:uppercase;
}

.button span{
    position: relative;
    z-index: 2;
  }

.button:after{
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: var(--primary-500);
    transition: all .35s;
    border-radius: 5px;
  }

.button:hover:after {
    width: 100%;
  }

.button:focus, .button:active {
    border: 1px solid white;
    outline: none;
}

.button-40 {
    width: 40%;
}

.button-50 {
    width: 50%;
}

.button-100 {
    width: 100%;
}

.button-primary {
    background-color: var(--primary);
    border: 1px solid var(--primary-500);
    color: white;
}
.button-primary:after{
    background-color: var(--primary-500);
}


.button-secondary {
    background-color: var(--secondary);
    border: 1px solid var(--secondary-500);
    color: white;
}
.button-secondary:after{
    background-color: var(--secondary-500);
}

.button-danger {
    background-color: #B70F0F;
    border: 1px solid #D53D3D;
    color: white;
}
.button-danger:after{
    background-color: #770808;
}

.button-green {
    background-color: #168612;
    border: 1px solid #10640d;
    color: white;
}
.button-green:after{
    background-color: #10640d;
}

.button-warning {
    color: #212529;
    background-color: #ffc107;
    border-color: #7B6715;
}
.button-warning:after{
    background-color: #e0a800;
}

.button-outline-primary{
    background-color: transparent;
    border: 1px solid var(--primary);
    color: var(--primary);
}
.button-outline-primary:after{
    background-color: white;
}

.button-outline-white{
    background-color: transparent;
    border: 1px solid white;
    color: white;
}
.button-outline-white:after{
    background-color: white;
}
.button-outline-white:hover{
    color: black;
}

.button-white{
    color: black;
    background-color: white;
    border: 1px solid white;
}
.button-white:hover {
    color: white;
}
.button-white:after{
    background-color: black;   
}

.disabled {
    background-color: gray;
    color: darkgray;
    border: 1px solid darkgray;
}

.disabled:after {
    background-color: gray;
}

.button a {
    color: white;
    position: relative;
    z-index: 2;
    font-weight: 600;
    text-decoration: none;
    text-align: center;
    font-size: 2rem;
    width: 100%;
}



.button-modal:after{
    margin: 0;
    background-color: white;
}
.button-modal:hover{
    color: black;
}

